import * as API from '@/api/index'

export default {
    // 列表
    getPurchaseAttributes: params => {
        return API.POST('api/purchaseAttributes/all', params)
    },
    //新增
    createPurchaseAttributes: params => {
        return API.POST('api/purchaseAttributes/create', params)
    },
    //编辑项目分类
    updatePurchaseAttributes:params => {
        return API.POST('api/purchaseAttributes/update', params)
    },
    // 一级列表
    getPurchaseAttributesTop: params => {
        return API.POST('api/purchaseAttributes/top', params)
    },
    //顺序调整
    movePurchaseAttributes:params => {
        return API.POST('api/purchaseAttributes/move', params)
    },

}